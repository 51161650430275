import { required as vuelidateRequire, helpers } from '@vuelidate/validators'

import { Maybe } from 'shared/graphql_types.ts'

import * as EmailValidator from 'email-validator'

export const errorsFor = validatedModel => {
  if (
    validatedModel &&
    validatedModel.$dirty &&
    validatedModel.$error
  ) {
    return {
      errors: validatedModel.$errors.map((error) => error.$message),
      errored: true
    }
  } else {
    return {
      errors: [],
      errored: false
    }
  }
}

export const noSpecialCharRegex = /^["”’\-,'_.a-zA-Z0-9À-ÿğłŞşőı\s]*$/

export const isValidEmail = (email: string) => EmailValidator.validate(email)

export const validateEmail = (field: string) => {
  return helpers.withMessage(
    `${field} must be a valid email address`,
    (value: Maybe<string>) => value === null || value === '' || isValidEmail(value)
  )
}

export const positiveInteger = (field: string, options = { allowZero: false }) => {
  return helpers.withMessage(
    `${field} must be a positive integer`,
    (value: Maybe<string | number>) =>
      value === null ||
      value === '' ||
      (Number.isInteger(Number(value)) && (options.allowZero ? Number(value) >= 0 : Number(value) > 0))
  )
}

export const validateMultipleEmails = (field: string) => {
  return helpers.withMessage(
    `${field} must contain at least one valid email address.`,
    (value: Maybe<string>) => {
      if (!value) return false
      const emails = value.split(',').map(email => email.trim())
      return emails.length > 0 && emails.every(isValidEmail)
    }
  )
}

export const cannotContainSpecialCharacters = (field: string) => {
  return helpers.withMessage(
    `${field} contains illegal characters`,
    (value: string | null) => value === null || noSpecialCharRegex.test(value)
  )
}

export const required = (field: string, errorMessage = `${field} is required`) => {
  return helpers.withMessage(errorMessage, vuelidateRequire)
}

export const atLeastOne = function <T> (field: string) {
  return helpers.withMessage(
    `${field} requires at least one entry`,
    (value: T[]) => value && value.length > 0
  )
}
